<template>
  <div class="con">
    <pwd-contair>
      <div slot="menu">密码重置</div>
      <div slot="pwdContair">
        <div class="pwdContair">
          <step-contair :active="2"></step-contair>
          <div class="pwdchangefrom">
            <div class="wid300">
              <el-form :rules="pwdChangeFormRule" :model="pwdChangeForm">
                <el-form-item>
                  <el-input
                    show-password
                    v-model="pwdChangeForm.newPassword"
                    placeholder="请输入新密码"
                  >
                    <span
                      slot="prefix"
                      class="iconfont icon-mima loginiconfont"
                    ></span>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-input
                    show-password
                    v-model="pwdChangeForm.pwdComfire"
                    placeholder="请再次确定新密码"
                  >
                    <span
                      slot="prefix"
                      class="iconfont icon-mima loginiconfont"
                    ></span>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button class="btn" type="primary" @click="submit()"
                    >提交</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </pwd-contair>
  </div>
</template>
<script>
import PwdContair from "@/components/supplier/pwdContair/pwdContair.vue";
import StepContair from "@/components/supplier/pwdContair/stepContair.vue";
import { updateUserPassword } from "@/api/user";
import { mapGetters } from "vuex";
export default {
  components: { PwdContair, StepContair },
  data() {
    return {
      pwdChangeForm: {
        newPassword: "",
        pwdComfire: "",
      },
      pwdChangeFormRule: {
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "change" },
        ],
        pwdComfire: [
          { required: true, message: "请再次确认新密码", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    submit() {
      if (this.pwdChangeForm.newPassword == this.pwdChangeForm.pwdComfire) {
        this.userInfo.password = this.pwdChangeForm.newPassword;
        console.log(this.userInfo.id + "和" + this.userInfo.password);
        updateUserPassword(this.userInfo.id, this.userInfo.password).then(
          (res) => {
            if (res.success) {
              this.$message.success(res.msg);
              this.$store.dispatch("LogOut").then(() => {
                this.$router.push("/supplier/supplierHome/resetSuccess");
              });
            } else {
              this.$message.error(res.msg);
            }
          }
        );
      } else {
        this.$message.error("密码不一致");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.pwdchangefrom {
  width: 100%;
  display: flex;
  justify-content: center;
  .el-button {
    width: 100%;
  }
  .el-input:focus-within {
    span {
      color: #ff5b0c;
    }
  }
}
</style>
